import { useCallback } from 'react';
import { Add, Array, Div, TIME, TODAY } from '@napp-inc/jnapp-util';
import { useSelector } from 'react-redux';
import { URL_CONST, useGetReportDetail } from '../../../../util';
import { ReduxReportTemplate } from '../../template';

export function RapportGainPerteChangeForm() {
    /**
     * @desc: Get data on redux store
     */
    const { deviseReference, taux } = useSelector((reduxState) => ({
        deviseReference: reduxState.deviseReference,
        taux: reduxState.taux.find((item) => item.type === 'TauxSystem')
    }));
    const CHANGE = `Change`;
    const GAINS_CHANGE = `GainChange`;
    const PERTES_CHANGE = `PerteChange`;
    const COMMISSION_ENTREE = `CommissionChangeEntree`;
    const COMMISSION_SORTIE = `CommissionChangeSortie`;
    const { formState, formDispatcher } = useGetReportDetail({
        defaultFields: {
            selectedOperation: { code: 'ACHAT', designation: 'Achat' },
            selectedDevise: { code: 'USD', designation: 'USD' },
            listHeaders: []
        }
    });

    const listFormater = useCallback(
        (donnees) => {
            const processValues = (type, obj) =>
                Object.values(obj?.[type] || {})?.map((item) => ({
                    ...item,
                    montant: 0
                }));
            const dataTorender = [];
            const levels = donnees?.[0]?.changes;
            // process values achats
            const achatsValues = processValues('achats', levels);
            // process values ventes
            const ventesValues = processValues('ventes', levels);
            // process values commissions entrées
            const commissionEntreeValues = processValues(
                'commissionsEntree',
                levels
            );
            // process values commissions sortie
            const commissionSortieValues = processValues(
                'commissionsSortie',
                levels
            );

            const organisationGrouped = Array.groupByProperty({
                array: (donnees?.[0]?.listReturn || []).map((item) => ({
                    ...item.detail,
                    operation: item.operation
                })),
                property: 'valeurTypeProprietaire.code'
            });

            const organisationGroupedKeys = Object.keys(
                organisationGrouped || {}
            );

            organisationGroupedKeys.forEach((group) => {
                const newDatas = {};
                const dataGrouped = organisationGrouped[group];
                const achats = { gain: 0, perte: 0, profit: 0 };
                const ventes = { gain: 0, perte: 0, profit: 0 };
                const commissionEntree = { gain: 0, perte: 0, profit: 0 };
                const commissionSortie = { gain: 0, perte: 0, profit: 0 };
                dataGrouped.forEach((data) => {
                    const { operation } = data;
                    newDatas.organisation = data.valeurTypeProprietaire;
                    const operationKeys = Object.keys(operation || {});
                    operationKeys.forEach((key) => {
                        // Gains Vente
                        if (
                            key.includes(GAINS_CHANGE) &&
                            data.devise === deviseReference.code
                        ) {
                            ventes.gain += operation[key]?.montant || 0;
                        }
                        const mappedVentesValues = ventesValues.map(
                            (item) => item
                        );
                        mappedVentesValues.forEach((mpv) => {
                            if (!ventes[mpv.value]) ventes[mpv.value] = 0;
                            ventes[mpv.value] +=
                                operation?.[key]?.ventes?.[mpv.value]
                                    ?.montant || 0;
                        });

                        // Gains Achat
                        if (
                            key.includes(GAINS_CHANGE) &&
                            data.devise !== deviseReference.code
                        ) {
                            achats.gain += Div(
                                operation[key]?.montant || 0,
                                taux?.valeur || 1
                            );
                        }
                        const mappedAchatsValues = achatsValues.map(
                            (item) => item
                        );
                        mappedAchatsValues.forEach((mpv) => {
                            if (!achats[mpv.value]) achats[mpv.value] = 0;
                            achats[mpv.value] +=
                                operation?.[key]?.achats?.[mpv.value]
                                    ?.montant || 0;
                        });

                        // Perte Vente
                        if (
                            key.includes(PERTES_CHANGE) &&
                            data.devise === deviseReference.code
                        ) {
                            ventes.perte += operation[key]?.montant || 0;
                        }

                        // Perte Achat
                        if (
                            key.includes(PERTES_CHANGE) &&
                            data.devise !== deviseReference.code
                        ) {
                            achats.perte += Div(
                                operation[key]?.montant || 0,
                                taux?.valeur || 1
                            );
                        }

                        // Gain Commission Entree
                        if (
                            key.includes(COMMISSION_ENTREE) &&
                            data.devise === deviseReference.code
                        ) {
                            commissionEntree.gain +=
                                operation[key]?.montant || 0;
                        }
                        const mappedCommissionsEntreeValues =
                            commissionEntreeValues.map((item) => item);
                        mappedCommissionsEntreeValues.forEach((mpv) => {
                            if (!commissionEntree[mpv.value])
                                commissionEntree[mpv.value] = 0;

                            commissionEntree[mpv.value] +=
                                operation?.[key]?.commissions?.[mpv.value]
                                    ?.montant || 0;
                        });
                        // Perte Commission Entree
                        if (
                            key.includes(COMMISSION_ENTREE) &&
                            data.devise !== deviseReference.code
                        ) {
                            commissionEntree.perte += Div(
                                operation[key]?.montant || 0,
                                taux?.valeur || 1
                            );
                        }
                        // Gain Commission Sortie
                        if (
                            key.includes(COMMISSION_SORTIE) &&
                            data.devise === deviseReference.code
                        ) {
                            commissionSortie.gain +=
                                operation[key]?.montant || 0;
                        }
                        const mappedCommissionsSortieValues =
                            commissionSortieValues.map((item) => item);

                        mappedCommissionsSortieValues.forEach((mpv) => {
                            if (!commissionSortie[mpv.value])
                                commissionSortie[mpv.value] = 0;

                            commissionSortie[mpv.value] +=
                                operation?.[key]?.commissions?.[mpv.value]
                                    ?.montant || 0;
                        });
                        // Perte Commission Sortie
                        if (
                            key.includes(COMMISSION_SORTIE) &&
                            data.devise !== deviseReference.code
                        ) {
                            commissionSortie.perte += Div(
                                operation[key]?.montant || 0,
                                taux?.valeur || 1
                            );
                        }
                    });
                });
                newDatas.achats = achats;
                newDatas.ventes = ventes;
                newDatas.commissionEntree = commissionEntree;
                newDatas.commissionSortie = commissionSortie;
                dataTorender.push({
                    ...newDatas,
                    profit: Add(
                        Add(
                            achats.gain,
                            ventes.gain,
                            commissionEntree.gain,
                            commissionSortie.gain
                        ),
                        Add(
                            achats.perte,
                            ventes.perte,
                            commissionEntree.perte,
                            commissionSortie.perte
                        )
                    )
                });
            });
            return dataTorender || [];
        },
        [
            COMMISSION_ENTREE,
            COMMISSION_SORTIE,
            GAINS_CHANGE,
            PERTES_CHANGE,
            deviseReference.code,
            taux?.valeur
        ]
    );

    const cutomTableHeader = useCallback((data) => {
        const headers = data?.[0]?.changes;
        const getHeaders = (type) => {
            if (Object?.keys(headers[type] || {})?.length) {
                return {
                    length: Object?.keys(headers[type] || {})?.length,
                    colSpan:
                        (Object?.keys(headers[type] || {})?.length || 0) + 2,
                    rowSpan: 1
                };
            }
            return {
                length: 0,
                colSpan: 2,
                rowSpan: 1
            };
        };
        const renderSubRows = (type) => (
            <>
                {Object?.keys(headers[type] || {}).map((item) => (
                    <th key={item} className="border">
                        {item}
                    </th>
                ))}
                {getHeaders(type) ? (
                    <>
                        <th className="border">Gain</th>
                        <th className="border">Perte</th>
                    </>
                ) : null}
            </>
        );
        return (
            <>
                <tr className="border text-center text-justify">
                    <th className="border" rowSpan={2}>
                        #
                    </th>
                    <th className="border" rowSpan={2}>
                        Organisation
                    </th>
                    <th
                        className="border"
                        colSpan={getHeaders('achats').colSpan}
                        rowSpan={getHeaders('achats').rowSpan}
                    >
                        Achats
                    </th>
                    <th
                        className="border"
                        colSpan={getHeaders('ventes').colSpan}
                        rowSpan={getHeaders('ventes').rowSpan}
                    >
                        Ventes
                    </th>
                    <th
                        className="border"
                        colSpan={getHeaders('commissionsEntree').colSpan}
                        rowSpan={getHeaders('commissionsEntree').rowSpan}
                    >
                        Commission Entrée
                    </th>
                    <th
                        className="border"
                        colSpan={getHeaders('commissionsSortie').colSpan}
                        rowSpan={getHeaders('commissionsSortie').rowSpan}
                    >
                        Commission Sortie
                    </th>
                    <th className="border" rowSpan={2}>
                        Profit
                    </th>
                </tr>
                <tr className="border text-center">
                    {renderSubRows('achats')}
                    {renderSubRows('ventes')}
                    {renderSubRows('commissionsEntree')}
                    {renderSubRows('commissionsSortie')}
                </tr>
            </>
        );
    }, []);

    const mapper = useCallback((data) => {
        const headers = data?.[0]?.changes;
        const getColumns = (type) => {
            const columns = [
                {
                    displayName: 'Gain',
                    field: `${type}.gain`,
                    // tdExtractor: (element) => element?.[type]?.gain || 0,
                    isTotal: true
                },
                {
                    displayName: 'Perte',
                    field: `${type}.perte`,
                    // tdExtractor: (element) => element?.[type]?.perte || 0,
                    isTotal: true
                }
            ];
            const headersData = Object.keys(headers?.[type] || {}).length
                ? Object.keys(headers[type]).map((item) => ({
                      displayName: item,
                      field: `${type}.${item}`,
                      //   tdExtractor: (element) => element?.[type]?.[item] || 0,
                      isTotal: true
                  }))
                : [];
            return headersData.concat(columns);
        };

        const achats = getColumns('achats');
        const ventes = getColumns('ventes');
        const commissionEntree = getColumns('commissionEntree');
        const commissionSortie = getColumns('commissionSortie');

        return [
            {
                displayName: 'Organisation',
                field: 'organisation.designation'
            },
            ...achats,
            ...ventes,
            ...commissionEntree,
            ...commissionSortie,
            {
                displayName: 'Profit',
                field: 'profit',
                isTotal: true
            }
        ].map((item) => ({
            ...item,
            Header: item?.displayName,
            accessor: item?.field
        }));
    }, []);

    return (
        <>
            {/* <StateShowerComponent state={formState?.fields} /> */}
            <ReduxReportTemplate
                cutomTableHeader={cutomTableHeader}
                listTitle={`${deviseReference?.code || ''} 1 = ${
                    taux?.valeur || ''
                } ${taux?.devise || ''} | Valeurs exprimées en ${
                    deviseReference.code
                }`}
                dynamicNodeName={`evolutionsGainPerteChange-${TIME.utc000000(
                    TODAY.at000000()
                )}-${TIME.utc235959(TODAY.at235959())}`}
                formState={formState}
                formDispatcher={formDispatcher}
                functionName={URL_CONST.GET_LIST_COMPTE_USER}
                listFormater={listFormater}
                formater={(data) => [data]}
                dynamicMapper={mapper}
                mapper={[]}
                isAddTotalTr
                payload={{
                    dateDebut: TIME.utc000000(TODAY.at000000()),
                    dateFin: TIME.utc235959(TODAY.at235959()),
                    typeEntite: 'Journalier',
                    type: CHANGE,
                    estObjet: true,
                    estOrganisation: true,
                    estAllOrganisation: true,
                    estNotDate: true
                }}
                defaultPayloadMapperValues={{
                    typeEntite: 'Journalier',
                    type: CHANGE,
                    estObjet: true,
                    typesEntite: ['VenteSuperDealer', 'VenteRevendeur'],
                    estOrganisation: true,
                    estAllOrganisation: true
                }}
                payloadMapper={[
                    {
                        name: 'dateDebut',
                        property: 'dateDebut',
                        isRequired: true,
                        displayName: 'Date début'
                    },
                    {
                        name: 'dateFin',
                        property: 'dateFin',
                        isRequired: true,
                        displayName: 'Date fin'
                    }
                ]}
            />
        </>
    );
}
